import React from "react";

const TermsConditionComponent = () => {
	return (
		<div className="component" id="terms-condition-component">
			<div id="header-container" className="container">
				<div className="heading">
					<h1>Bellex Terms and Conditions (T&C)</h1>
				</div>
				<div className="heading-content content">
					<p className="paragraph">
						Bellex is the flagship brand of M/s BNB MARKET TECHNOLOGIES LLP
						hereinafter referred to as
						<span className="text-bold">("the Company")</span>. The Company
						offers the users to participate in various games or leagues{" "}
						<span className="text-bold">("Event"/ "Events")</span>
						and various services
						<span className="text-bold">
							{" "}
							("Bellex Services"/ "Services")
						</span>{" "}
						which are available on https://bellex.in and Bellex mobile
						application (individually and collectively referred to as{" "}
						<span className="text-bold">
							{" "}
							"Portal" / "Bellex Portal" / "Bellex Platform")
						</span>
						. Any person (hereinafter referred to as{" "}
						<span className="text-bold">
							"the User" or "you" or "your" )
						</span>{" "}
						"the User" or "you" or "your" ) accessing or utilizing the Bellex
						Portal or any of its features including participation in Events
						being conducted and offered on the Bellex Portal to the User by the
						Company and/or its affiliates; subsidiaries; associates and
						partners, shall be bound and governed by these Terms and Conditions{" "}
						<span className="text-bold"> ("T&C")</span>
						and all other rules; regulations and terms of use referred to herein
						or provided by the Company in relation to any Bellex Services. These
						T&C read along with the Privacy Policy, disclaimers referred herein
						or provided by the Company from time to time, shall govern your
						use/access of the Bellex Platform, its related content, sites and
						hyperlinks owned maintained and operated by the Company and or its
						affiliates; subsidiaries; associates and partners. These T&C apply
						to all Users of the Bellex Portal.
					</p>
					<p className="paragraph">
						The Bellex Portal is an educational informational platform which
						aims to bring awareness among people about financial markets through
						financial market events and learning modules, and mock games. It
						boosts financial literacy through gamification whereby, involving
						considerable skill, judgement and discretion. "The Element of Skill"
						has a predominant influence on the outcome of the event selected by
						the User. Success arises out of Users' exercise of superior
						knowledge, judgement and attention.
					</p>
					<p className="paragraph">
						You understand that T&C will be binding on you. You agree that
						Services offered on the Portal can be accessed in accordance with
						the T&C and you shall be responsible to comply with T&C at all
						times. The Company shall be entitled to modify these T & C, rules,
						regulations and terms of use referred to herein or provided by it in
						relation to any Services, at any time, by duly posting the same on
						the Bellex Portal. The usage of the Bellex Portal constitutes the
						User's acceptance of such T & C, rules, regulations and terms of use
						referred to herein or provided by the Company in relation to any
						Bellex Services, as may be amended from time to time. The Company
						may, at its sole discretion, also notify the User of any change or
						modification in these T & C, rules, regulations and terms of use
						referred to herein or provided by the Company, by way of sending an
						email to the User's registered email address or posting
						notifications in the User accounts. The User may then exercise the
						options provided in such an email or notification to indicate
						non-acceptance of the modified T & C, rules, regulations and terms
						of use referred to herein or provided by the Company. If such
						options are not exercised by the User within the time frame
						prescribed in the email or notification, the User will be deemed to
						have accepted the modified T & C, rules, regulations and terms of
						use referred to herein or provided by the Company.
					</p>
					<p className="paragraph">
						The User's use of the Bellex Portal shall be subject to:
						<ul>
							<li>
								These T & C:
								<ul>
									<li>
										The Privacy Policy and any other terms, conditions, notices
										or disclaimers displayed or added to the Bellex Portal
										and/or the Customer Agreement form by the Company or any
										other document signed by the User;
									</li>
									<li>
										The Service(s) and Bellex Portal are provided for the User's
										personal and non-commercial use only. The User agrees not to
										reproduce, retransmit, distribute, disseminate, sell,
										broadcast, perform, make available to third parties or
										circulate the credentials to access the Portal or any
										material, content through the Bellex Portal, or to exploit
										any content for commercial purposes without the express
										prior written consent of the Company;
									</li>
								</ul>
							</li>
						</ul>
						Certain Bellex Services being provided on the Portal may be subject
						to additional rules and regulations set down in that respect. To the
						extent that these T&C are inconsistent with the additional
						conditions set down, the additional conditions shall prevail.
					</p>
					<p>
						If any of the T&C are determined to be unlawful, invalid, void or
						unenforceable for any reason by any judicial or quasi-judicial body
						of India, it will not affect the validity and enforceability of the
						remaining T&C. Our failure or delay to act or exercise any right or
						remedy with respect to a breach of any of the T&C by you shall not
						be construed as waiver of our right to act with respect to the said
						breach or any prior, concurrent, subsequent or similar breaches.
					</p>
					<p>
						If you do not wish to adhere to our T&C or any of our future changes
						or modifications to our T&C, you may choose to cease the use of our
						Services and any and all features of our Bellex Portal alongwith the
						Services we offer.
					</p>
				</div>
			</div>
		</div>
	);
};

export default TermsConditionComponent;

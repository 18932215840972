import { useState } from "react";
import { Image } from "semantic-ui-react";

const LandingComponent = (props) => {
	const [state, setState] = useState("We are in development mode");

	return (
		<>
			<div className="header">
				<Image src="./images/Bellex-SVG.svg" alt="logo" className="logo" />
			</div>
			<div className="content">
				<section className="modules">
					<div className="modules__learn">
						<Image
							src="./images/B Circle Open File1.svg"
							alt="learn"
							className="modules__learn-img"
						/>
						<div className="modules__learn-title">Learn</div>
					</div>
					<div className="modules__play">
						<Image
							src="./images/B Circle Open File2.svg"
							alt="play"
							className="modules__play-img"
						/>
						<div className="modules__play-title">Play</div>
					</div>
					<div className="modules__earn">
						<Image
							src="./images/B Circle Open File3.svg"
							alt="earn"
							className="modules__earn-img"
						/>
						<div className="modules__earn-title">Earn</div>
					</div>
				</section>
				<div className="spacer">&nbsp;</div>
				<div className="comingsoon">{state}</div>
				<div className="spacer">&nbsp;</div>
				<div className="spacer">&nbsp;</div>
				<section className="hiring">
					<div className="hiring__heading">
						<span className="hashtag">#</span>
						<span className="word-1">We</span>
						<span className="word-2">Are</span>
						<span className="word-3">Hiring</span>
					</div>
					<div className="hiring__contactus">
						To be a part of this exciting journey, share your profile at{" "}
						<span className="hiring__contactus-email">contact@bellex.in</span>
					</div>
					<div className="hiring__content">
						<div className="hiring__nodejs">
							<h2 className="hiring__nodejs__heading">Node.js</h2>
							<ul className="hiring__nodejs__content">
								<li>
									2-3+ years of creating application in Node.js along with
									Nestjs framework
								</li>
								<li>Strong proficiency with JavaScript</li>
								<li>Experience in building and consuming REST APIs</li>
								<li>Experience with Git versioning tool </li>
								<li>
									Knowledge of relational databases (e.g. MySQL) with TypeORM
								</li>
								<li>Knowledge with Docker will be an advantage</li>
								<li>
									Experience with JavaScript unit test frameworks such as Jest
									will be an advantage
								</li>
							</ul>
						</div>
						<div className="hiring__uiux">
							<h2 className="hiring__uiux__heading">UI/UX</h2>
							<ul className="hiring__uiux__content">
								<li>
									Proven work experience as a UI/UX Designer or similar role
									with portfolio of design projects
								</li>
								<li>
									Gather and evaluate user requirements in collaboration with
									product managers and engineers
								</li>
								<li>
									Illustrate design ideas using storyboards, process flows and
									sitemaps
								</li>
								<li>
									Design graphic user interface elements, like menus, tabs,
									widgets, page navigation buttons and search fields
								</li>
								<li>
									Develop UI mockups and prototypes that clearly illustrate how
									sites function and look like
								</li>
								<li>
									Create original graphic designs (e.g. images, sketches and
									tables)
								</li>
								<li>Conduct layout adjustments based on user feedback</li>
								<li>Adhere to style standards on fonts, colors and images</li>
								<li>
									The designer should follow the design process and should start
									the work at prototyping stage
								</li>
							</ul>
						</div>
						<div className="hiring__flutter">
							<h2 className="hiring__flutter__heading">Flutter Developer</h2>
							<ul className="hiring__flutter__content">
								<li>
									2-3+ years of creating multi-platform apps for iOS and Android
									with Flutter
								</li>
								<li>BLOC pattern in Flutter</li>
								<li>Strong proficiency of Dart Language</li>
								<li>Good in REST and API Integration</li>
								<li>Deployment of application on Play store/App store</li>
								<li>Experience with Git versioning tool </li>
								<li>
									Good to have of building custom packages in Flutter using the
									functionalities and APIs already available in native Android
									and IOS.
								</li>
							</ul>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default LandingComponent;

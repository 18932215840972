import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.scss";
import LandingComponent from "./components/LandingComponent";
import PrivacyComponent from "./components/PrivacyComponent";
import TermsConditionComponent from "./components/TermsCondition";

function App() {
	return (
		<div className="app">
			<Switch>
				<Route exact path="/" component={LandingComponent} />
				<Route exact path="/privacy-policy" component={PrivacyComponent} />
				<Route
					exact
					path="/terms-condition"
					component={TermsConditionComponent}
				/>
			</Switch>
		</div>
	);
}

export default App;

import React from "react";

const PrivacyComponent = () => {
	return (
		<div className="component" id="privacy-component">
			<div id="header-container" className="container">
				<div className="heading">
					<h1>Bellex Privacy Policy</h1>
				</div>
				<div className="heading-content content">
					<p className="paragraph">
						BNB Market Technologies LLP operates{" "}
						<span className="text-bold">Bellex</span> in India, which offers
						financial market based games through the mobile application Bellex
						(hereinafter referred to as the “Bellex”) (BNB Market Technologies
						LLP referred to herein as <span className="text-bold">“BNB”</span>{" "}
						or <span className="text-bold">“Bellex”,</span>
						<span className="text-bold"> &quot;we&quot;</span> or
						<span className="text-bold"> &quot;us&quot;</span> , &{" "}
						<span className="text-bold">&quot;our&quot;</span>).
					</p>
					<p className="paragraph">
						Any person utilizing the Bellex{" "}
						<span className="text-bold">("User" or "you" or "your")</span> or
						any of its features including participation in the various games /
						leagues
						<span className="text-bold">("Game") (" Services ")</span> being
						conducted on the mobile application) shall be bound by this Privacy
						Policy.
					</p>
					<p className="paragraph">
						Most of the Services are offered for free but you may need
						registration to participate in
						<span className="text-bold"> Bellex</span> games. The information
						about the User is collected by{" "}
						<span className="text-bold">Bellex</span> as (i) information
						supplied by Users and (ii) information automatically tracked during
						User's navigation on Bellex.
					</p>
					<p className="paragraph">
						Before you submit any information to Bellex, please read this
						Privacy Policy for an explanation of how we will treat your personal
						information. By using any part of the Bellex, you consent to the
						collection, use, disclosure and transfer of your personal
						information for the purposes outlined in this Privacy Policy and to
						the collection, processing and maintenance of this information. If
						you do not agree to this Privacy Policy, please do not use Bellex.
						Your use of any part of Bellex indicates your acceptance of this
						Privacy Policy and of the collection, use and disclosure of your
						personal information in accordance with this Privacy Policy. While
						you have the option not to provide us with certain information or
						withdraw consent to collect certain information, kindly note that in
						such an event you may not be able to take full advantage of the
						entire scope of features and services offered to you and we reserve
						the right not to provide you with our services.
					</p>
				</div>
			</div>

			<div id="section-container" className="container">
				<div id="section-1" className="section">
					<div className="section-heading">1. Usage</div>
					<div className="section-content content">
						<p className="paragraph">
							To avail certain Services on the Bellex, Users would be required
							to provide certain information for the registration process
							namely:
							<ul>
								<li>Username</li>
								<li>Password</li>
								<li>Email Address</li>
								<li> Mobile Number</li>
							</ul>
							In the course of providing you with access to the Services, and in
							order to provide you access to the features offered through Bellex
							and to verify your identity, you may be required to furnish
							additional information, including your Permanent Account Number
							and Bank Account Details.
						</p>
						<p className="paragraph">
							All required information is specific and based on the kind of
							Game/ Services the User wishes to participate in or access, and
							will be utilized for the purpose of providing services, including
							but not limited to the Services requested by the User. The
							information as supplied by the Users enables us to improve the
							Services and provide you the most user-friendly game experience.
							Bellex may also share such information with affiliates and third
							parties in limited circumstances, including for the purpose of
							providing services requested by the User, complying with legal
							process, preventing fraud or imminent harm, and ensuring the
							security of our network and services. This includes financial
							information, such as information regarding the payment
							instrument/modes used by you to make such payments, which may
							include cardholder name, credit/debit card number (in encrypted
							form) with expiration date, banking details, wallet details etc.
							This information is presented to you at the time of making a
							payment to enable you to complete your payment expeditiously.
						</p>
						<p className="paragraph">
							In the course of providing the Services, Users may invite other
							existing Users or other users{" "}
							<span className="text-bold">("Invited Users") </span> to
							participate in any of the Services by providing the email address
							or mobile number of such users. Bellex may thereafter use this
							information to contact the Invited User and invite such user to
							register with Bellex (if such Invited User is not an existing
							User) and participate in the Game in relation to which such person
							was invited by the User. The participation of the Invited User in
							any of the leagues / games shall be subject to the terms of this
							Privacy Policy and the Terms and Conditions for the use of Bellex.
							The User hereby represents that the Invited Users have consented
							and agreed to such disclosure to and use of their email address
							and mobile number by Bellex.
						</p>
					</div>
				</div>
				<div id="section-2" className="section">
					<div className="section-heading">2. Disclosure</div>
					<div className="section-content content">
						<p className="paragraph">
							Bellex may also share information as provided by you and data
							concerning usage of the Services and participation in the Games
							with third party service providers engaged by Bellex, for the
							purpose of data analytics or other similar purposes, for the
							purpose of storage, improving the services and helping Bellex
							serve you better. By using Bellex, you hereby expressly agree and
							grant consent to the collection, use and storage of this
							information by Bellex. Bellex reserves the right to share,
							disclose and transfer information collected hereunder with its own
							affiliates. In the event Bellex sells or transfers all or a
							portion of its business assets, consumer information may be one of
							the business assets that are shared, disclosed or transferred as
							part of the transaction. You hereby expressly grant consent and
							permission to Bellex for disclosure and transfer of information to
							such third parties. Bellex may share information as provided by
							you and data concerning usage of the Services and participation in
							the Game with its commercial partners for the purpose of
							facilitating user engagement, for marketing and promotional
							purposes and other related purposes. Further, Bellex reserves the
							right to disclose personal information as obligated by law, in
							response to duly authorized legal process, governmental requests
							and as necessary to protect the rights and interests of Bellex.
						</p>
					</div>
				</div>
				<div id="section-3" className="section">
					<div className="section-heading">3. Cookies</div>
					<div className="section-content content">
						<p className="paragraph">
							To improve the effectiveness and usability of Bellex for our
							Users, we use "cookies", or such similar electronic tools to
							collect information to assign each visitor a unique random number
							as a User Identification (User ID) to understand the User's
							individual interests using the identified computer. Unless the
							User voluntarily identifies himself/herself (e.g., through
							registration), Bellex has no way of knowing who the User is, even
							if we assign a cookie to the User's computer. The only personal
							information a cookie can contain is information supplied by the
							User. A cookie cannot read data off the User's hard drive.
							Bellex’s advertisers may also assign their own cookies to the
							User's browser (if the User clicks on their ad banners), a process
							that Bellex does not control.
						</p>
						<p className="paragraph">
							Bellex’s web servers automatically collect limited information
							about User's computer's connection to the Internet, including
							User's IP address, when the User visits Bellex. (User's IP address
							is a number that lets computers attached to the Internet know
							where to send data to the User such as the web pages viewed by the
							User). The User's IP address does not identify the User
							personally. Bellex uses this information to deliver its web pages
							to Users upon request, to tailor it to the interests of its users,
							to measure traffic within Bellex and let advertisers know the
							geographic locations from where Bellex’s visitors come.
						</p>
					</div>
				</div>
				<div id="section-4" className="section">
					<div className="section-heading">4. Links</div>
					<div className="section-content content">
						Bellex may includes links to other websites. Such websites are
						governed by their respective privacy policies, which are beyond
						Bellex’s control. Once the User leaves Bellex servers (the User can
						tell where he/she is by checking the URL in the location bar on the
						User's browser), use of any information provided by the User is
						governed by the privacy policy of the operator of the site which the
						User is visiting. That policy may differ from Bellex’s own. If the
						User can't find the privacy policy of any of these sites via a link
						from the site's homepage, the User may contact the site directly for
						more information. Bellex is not responsible for the privacy
						practices or the content of such websites.
					</div>
				</div>
				<div id="section-5" className="section">
					<div className="section-heading">5. Security</div>
					<div className="section-content content">
						<p className="paragraph">
							All information gathered on Bellex is securely stored within
							Bellex controlled database. The database is stored on servers
							secured behind a firewall; access to such servers being
							password-protected and strictly limited based on need-to-know
							basis. However, we understand that as effective as our security
							measures are, no security system is impenetrable. Thus, we cannot
							guarantee the security of our database, nor can we guarantee that
							information you supply will not be intercepted while being
							transmitted to us over the Internet. Further, any information you
							include in a posting to the discussion areas will be available to
							anyone with Internet access. By using Bellex, you understand and
							agree that your information may be used in or transferred to
							countries other than India.
						</p>
						<p className="paragraph">
							Bellex also believes that the internet is an ever-evolving medium.
							We may periodically review from time to time and change our
							privacy policy to incorporate such future changes as may be
							considered appropriate, without any notice to you. Our use of any
							information we gather will always be consistent with the policy
							under which the information was collected, regardless of what the
							new policy may be. Any changes to our privacy policy will be
							posted on this page, so you are always aware of what information
							we collect, how we use it, how we store it and under what
							circumstances we disclose it.
						</p>
						<p className="paragraph">
							BELLEX DOES NOT WARRANT THAT THIS PLATFORM, IT’S SERVERS, OR EMAIL
							SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. BELLEX WILL NOT BE
							LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF BELLEX,
							INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT,
							INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF
							DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR
							DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL
							BELLEX BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS
							OF AN AMOUNT OF INR 100.
						</p>
					</div>
				</div>
				<div id="section-6" className="section">
					<div className="section-heading">6. Data</div>
					<div className="section-content content">
						<p className="paragraph">
							Your personal information may be retained and may continue to be
							used until: (i) the relevant purposes for the use of your
							information described in this Privacy Policy are no longer
							applicable; and (ii) we are no longer required by applicable law,
							regulations, contractual obligations or legitimate business
							purposes to retain your personal information and the retention of
							your personal information is not required for the establishment,
							exercise or defense of any legal claim.
						</p>
					</div>
				</div>
				<div id="section-7" className="section">
					<div className="section-heading">
						7. Applicable law & Jurisdiction
					</div>
					<div className="section-content content">
						By visiting Bellex, you agree that the laws of the Republic of India
						without regard to its conflict of laws principles, govern this
						Privacy Policy and any dispute arising in respect hereof shall be
						subject to and governed by the dispute resolution process set out in
						the Terms & Conditions
					</div>
				</div>
				<div id="contact-us" className="section">
					<div className="section-heading">8. Contact Us</div>
					<div className="section-content content">
						<p className="paragraph">
							{" "}
							<span className="text-bold">Email : </span>
							<a href="contact@bellex.in">contact@bellex.in</a>
						</p>
						<p className="paragraph" id="contact-us__address">
							<span className="text-bold"> Corporate Office:</span>
							<br />
							BNB Market Technologies LLP
							<br />
							S-31 Sej Plaza,
							<br />
							Marve Road, Malad West, Mumbai - 400064.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyComponent;
